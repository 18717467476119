<script setup lang="ts">
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'

const localFirebaseUser = useFirebaseUser()
const showRegisterForm = ref(false)
const registerMessage = ref()
const registerForm = ref({ email: '', password: '' })
const signinForm = ref({ email: '', password: '' })

definePageMeta({
  layout: 'dev',
})

const auth = useAuth()
const provider = new GoogleAuthProvider()

const toggleButtonText = computed(() => {
  return showRegisterForm.value ? 'Sign in' : 'Register'
})

const signInWithGoogle = async () => {
  await signInWithPopup(auth, provider)
}

const signin = async () => {
  await FirebaseAuthentication.signInWithEmailAndPassword({
    email: signinForm.value.email,
    password: signinForm.value.password,
  })

  signinForm.value = { email: '', password: '' }
}

const signout = async () => {
  await FirebaseAuthentication.signOut()
}

const register = async () => {
  console.log(registerForm.value)
  const credentials = await createUser(registerForm.value.email, registerForm.value.password)
  registerForm.value = { email: '', password: '' }
  if (credentials) {
    registerMessage.value = `Successfully registered: ${credentials.user.email}`
    setTimeout(() => {
      registerMessage.value = ''
    }, 3000)
  }
}
</script>
<template>
  <div class="mx-auto p-4 pt-8">
    <div v-if="localFirebaseUser">
      Logged in as {{ localFirebaseUser.email }} <CommonButton @click="signout" :dark="true">Logout</CommonButton>
    </div>

    <h1 class="text-3xl font-bold">PictureClub</h1>
    <Navigation />
    <div>
      <div class="container mt-6" v-if="!localFirebaseUser">
        <div class="column is-half is-offset-one-quarter">
          <AuthFirebase
            class="box px-5 py-5 mx-4"
            title="Register"
            @submit="register"
            :form="registerForm"
            :message="registerMessage"
            v-if="showRegisterForm"
          />
          <AuthFirebase
            class="box px-5 py-5 mx-4"
            title="Sign in"
            @submit="signin"
            :form="signinForm"
            v-if="!localFirebaseUser && !showRegisterForm"
          />
          <CommonButton class="my-2" @click="signInWithGoogle">Sign in with Google </CommonButton>
        </div>
        <div class="level">
          <div class="level-item has-text-centered">
            <CommonButton class="my-2" @click="showRegisterForm = !showRegisterForm">
              {{ toggleButtonText }}
            </CommonButton>
          </div>
        </div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
